.Options > ul > li {
  list-style-type: none;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.Options {
  display: inline-flex;
}
$darkAzure: darken(azure, 95%);
.LeftSide {
  text-align: right;
  padding-right: 0.25em;
  color: azure;
  text-shadow: 1px 1px 2px $darkAzure;
}
$darkOrange: darken(darkorange, 95%);
.RightSide {
  color: darkorange;
  text-shadow: 1px 1px 2px $darkOrange;
  text-align: left;
  padding-left: 0.25em;
}
