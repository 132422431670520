$cAccent: #3978ef;
$cBlack: #121212;
$cGray: #333;
$cLightGray: rgba(#000, 0.1);

.accordion-list {
  list-style: none;
  margin: 0;
  padding: 0;
  border-radius: 0.4rem;
  overflow: hidden;
  box-shadow: 0 0 0.8rem 0.1rem rgba(darken($cAccent, 20%), 0.06),
    0 20px 30px -10px rgba(darken($cAccent, 20%), 0.2);

  &__item {
    & + & {
      border-top: 1px solid $cLightGray;
    }
  }
}

.accordion-item {
  $self: &;

  &--opened {
    #{ $self }__icon {
      transform: rotate(180deg);
    }

    #{ $self }__inner {
      max-height: 100rem;
      transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
      transition-duration: 0.5s;
      transition-property: max-height;
    }

    #{ $self }__content {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.2s;
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
      transition-property: opacity, transform;
    }
  }

  &__line {
    display: block;
    padding: 0.8rem 1.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    position: relative;
  }

  &__title {
    font-size: 1.6rem;
    margin: 0;
    font-weight: 700;
  }

  &__icon {
    width: 1.2rem;
    height: 1.2rem;
    transition: transform 0.3s ease-in-out;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAQAAABIkb+zAAAAAmJLR0QA/4ePzL8AAAEASURBVHja7dhLbsJQEAXRV1kR+59lLVmImUVKlI9f920jRJ0x0u3CeMJakiRJkiRJkiTp1XDmQ8fxoONOXPf2Ek/gMc8AggHXJ0D0CVydAOGf0LUJEH8HrkyAgZf4ugQ2L2J/YjKB7XuozEwlULiG2tREAqVbqM6lEyheQn0ymUD5DjqzqQQaV9CbTiTQuoHufDeB5gX0v8FOAu19Er/hagKBdTKvYSWByHYoYD+B0HIsYC+B2G4w4HwCwdVowLkEopvhgP8TCC/GA/5OIL43EPB7AgNrIwE/JzCyNfTP3PdjP2Doqxp0fHpfT+rJz19rrdu6LUmSJEmSJEmSJH1xBxOSRtnN4xZaAAAAAElFTkSuQmCC);
    opacity: 0.9;
  }

  &__inner {
    max-height: 0;
    overflow: hidden;
    text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition-duration: 0.5s;
    transition-property: max-height;
    z-index: 1;
    position: relative;
    padding-bottom: 12px;
  }

  &__content {
    opacity: 0;
    transform: translateY(-1rem);
    transition-timing-function: linear, ease;
    transition-duration: 0.1s;
    transition-property: opacity, transform;
    transition-delay: 0.5s;
    padding: 0 1.2rem 1.2rem;
  }

  &__paragraph {
    margin: 0;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.3;
  }
}

.wrapper {
  margin: 0 auto;
  width: 90%;
}
