$darkAzure: darken(azure, 95%);
.App {
  background-attachment: fixed;
  background-color: #0d1520;
  background-image: url(./GalaxyAtWar.png);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  color: azure;
  font-size: 18px;
  line-height: 1.6em;
  margin: 0 auto;
  min-height: 100vh;
  position: relative;
  text-align: center;
  transform-origin: 0 0;
  z-index: 1;

  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
}

.Body-wrapper {
  max-width: 960px;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  min-height: 20vh;
}

.App-link {
  color: #09d3ac;
}

.galaxy {
  left: 0;
  position: absolute;
  top: 0;
  z-index: 0;
}

$shepardBlue: #1c3d75;
$darkBlue: darken($shepardBlue, 95%);
.refresh-button {
  background-color: $shepardBlue;
  border-radius: 10%/20%;
  border: 0;
  box-shadow: 1px 2px 2px $darkBlue;
  color: azure;
  font-size: 100%;
  font-weight: 700;
  margin: 1rem 0 1rem;
  padding: 0.8rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
.error {
  color: azure;
  text-shadow: 1px 1px 2px $darkAzure;
}
